import { useCallback, useEffect, useRef, useState } from 'react';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import Webcam from 'react-webcam';
import { FiArrowLeft, FiCamera, FiRefreshCw, FiVideo, FiSmartphone } from 'react-icons/fi';
import { ImSpinner8 } from 'react-icons/im';

import { useGetNoteBookCaptureQuery } from 'store/apiSlices/inClass/evaluation.apiSlice';
import { useAddPracticeCapturesMutation } from 'store/apiSlices/studentDashboard.apiSlice';

import { Modal, ModalBottomSheet } from 'components/common';
import { ImageModal, PracticePermissionModal } from 'components/screens';

import PortableTextWithLatex from 'components/common/PortableTextWithLatex';
import { IS_IOS_DEVICE, LOG_TYPE, STUDENT_PATHS } from 'configs';
import { useLogger, useWindowDimensions } from 'hooks';
import { ICaptureState, IEvaluationImage, IQrCodeCapture } from 'types';
import { checkMediaPermissions, dataURLtoFile, listenToMediaPermissions } from 'utils';

export default function QRCodeCapture({
  params,
  showQuestion,
  evalName,
  noteId,
  selectedQuestionData,
  questionData,
  selectedQuestionIndex,
  setShowQuestion,
  refetch,
  handleQrCodeCapture,
  setNoteId,
}: IQrCodeCapture) {
  const webcamRef = useRef<Webcam>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const { isMobileScreen } = useWindowDimensions();
  const { logInfo } = useLogger();
  const navigate = useNavigate();
  const scrollRef = useRef<HTMLDivElement>(null);

  const [facingMode, setFacingMode] = useState<'user' | 'environment'>('environment');
  const [captureState, setCaptureState] = useState<ICaptureState>({
    loading: false,
    timeoutId: null,
    isTimeOut: false,
  });
  const [videoPermissionDenied, setVideoPermissionDenied] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
  const [capturedImage, setCapturedImage] = useState<IEvaluationImage[]>([]);
  const [pages, setPages] = useState<number>(1);
  const [isRecapture, setIsRecapture] = useState<boolean>(false);
  const [useWebcam, setUseWebcam] = useState<boolean>(false);

  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [scrollRef, selectedQuestionData]);

  /* API -> FOR CAPTURE AND STORE THE IMAGES  */
  const [addPracticeCaptures] = useAddPracticeCapturesMutation();

  /* API -> FOR GETTING THE CAPTURED NOTEBOOK DATA */
  const { data: capturedNoteBookData, refetch: getNoteBookCapture } = useGetNoteBookCaptureQuery(
    {
      practiceSheetId: params?.practiceSheetId,
      evaluationId: params?.evaluationId,
    },
    {
      skip: !params?.practiceSheetId || !params?.evaluationId,
    },
  );

  useEffect(() => {
    if (capturedNoteBookData) {
      if (isRecapture) {
        setPages(capturedNoteBookData?.data?.length || 1);
      } else {
        setPages(capturedNoteBookData?.data?.length + 1);
      }
    }
  }, [capturedNoteBookData, isRecapture]);

  const switchCamera = () => {
    const newFacingMode = facingMode === 'user' ? 'environment' : 'user';
    setFacingMode(newFacingMode);
    if (fileInputRef.current) {
      try {
        // Update the capture attribute based on the new facingMode
        fileInputRef.current.setAttribute('capture', newFacingMode);
      } catch (error) {
        console.error('Error setting capture attribute:', error);
      }
    }
  };

  const refreshWebcam = useCallback(() => {
    if (webcamRef.current && webcamRef.current.video) {
      // Clear previous stream
      const video = webcamRef.current.video;
      if (video.srcObject) {
        (video.srcObject as MediaStream).getTracks().forEach((track) => track.stop());
        video.srcObject = null;
      }
      // Reinitialize the video stream
      navigator.mediaDevices
        .getUserMedia({ video: { facingMode } })
        .then((stream) => {
          if (video) {
            video.srcObject = stream;
            video.muted = true;
            video.playsInline = true;
          }
        })
        .catch((error) => toast.error('Error accessing media devices.', error));
    }
  }, [facingMode]);

  const fetchMediaPermissions = useCallback(async () => {
    const permissions = await checkMediaPermissions();
    setVideoPermissionDenied(permissions.videoPermissionDenied);
    if (!permissions.videoPermissionDenied) {
      refreshWebcam();
      setCaptureState((prevState) => ({ ...prevState, loading: false, isTimeOut: false }));
    }
  }, [refreshWebcam]);

  const handleNativeCapture = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (!file) return;

    setCaptureState((prevState) => ({ ...prevState, loading: true }));
    if (captureState.timeoutId) {
      clearTimeout(captureState.timeoutId);
    }

    try {
      const { evaluationId, questionId, practiceSheetId, studentId } = params;

      logInfo(
        `Practice sheet capture for the evaluationId -> ${evaluationId}, practiceSheetId -> ${practiceSheetId} , studentId -> ${studentId} , questionId -> ${questionId} `,
        {
          evaluationId,
          practiceSheetId,
          studentId,
          questionId,
        },
        LOG_TYPE.INFO,
      );

      const imageId = Date.now();
      const fileName = `${questionId}_${studentId}_${imageId}.${file.name.split('.').pop()}`;

      const formData = new FormData();
      formData.append('images', file, fileName);
      formData.append('evaluationId', evaluationId || '');
      formData.append('practiceSheetId', practiceSheetId || '');
      if (noteId && noteId !== 0) {
        formData.append('noteId', noteId?.toString());
      } else {
        formData.append('page', pages.toString());
      }

      const questionFilesMap = {
        [questionId || '']: [fileName],
      };
      formData.append('questionImagesMap', JSON.stringify(questionFilesMap));

      const timeout = setTimeout(() => {
        setCaptureState({ loading: false, timeoutId: null, isTimeOut: true });
        toast.error('Capture timeout. Please try again.');
      }, 15000);

      setCaptureState((prevState) => ({ ...prevState, timeoutId: timeout }));

      addPracticeCaptures({ formData })
        .unwrap()
        .then((response) => {
          clearTimeout(timeout);
          const imageUrls = response?.data?.imageUrls;
          if (Array.isArray(imageUrls)) {
            setCapturedImage(imageUrls);
            setIsOpenModal(true);
          }
          setCaptureState({ loading: false, timeoutId: null, isTimeOut: false });
          logInfo(
            `Successfully captured the practice sheet for evaluationId: ${evaluationId}, practiceSheetId: ${practiceSheetId}, studentId: ${studentId}, questionId: ${questionId}`,
            {
              evaluationId,
              practiceSheetId,
              studentId,
              questionId,
            },
            LOG_TYPE.INFO,
          );
          getNoteBookCapture();

          // Reset file input value
          if (fileInputRef.current) {
            fileInputRef.current.value = '';
          }
        })
        .catch((error) => {
          clearTimeout(timeout);
          if (error?.data?.data?.isAlreadySubmitted) {
            toast.error('Your practice paper has already been submitted.');
            setTimeout(() => {
              navigate(STUDENT_PATHS.STUDENT_PRACTICE, {
                replace: true,
              });
            }, 1000);
          } else if (error.status === 'FETCH_ERROR') {
            toast.error(
              'Network error occurred. Please check your internet connection and try again.',
            );
          } else {
            toast.error(error.data?.message);
          }
          setCaptureState({ loading: false, timeoutId: null, isTimeOut: false });

          // Reset file input value
          if (fileInputRef.current) {
            fileInputRef.current.value = '';
          }
        });
    } catch (err) {
      clearTimeout(captureState.timeoutId || undefined);
      toast.error(err?.toString() || '');
      setCaptureState({ loading: false, timeoutId: null, isTimeOut: false });

      // Reset file input value
      if (fileInputRef.current) {
        fileInputRef.current.value = '';
      }
    }
  };

  const switchToWebcam = () => {
    setUseWebcam(true);
    setTimeout(() => {
      fetchMediaPermissions();
    }, 500);
  };

  const capture = useCallback(async () => {
    if (!useWebcam) {
      // Try native approach first
      if (fileInputRef.current) {
        fileInputRef.current.click();
      }
      return;
    }

    // Fallback to webcam approach
    const permissions = await checkMediaPermissions();

    listenToMediaPermissions(({ videoPermissionDenied }) => {
      setVideoPermissionDenied(videoPermissionDenied);
    });

    if (permissions.videoPermissionDenied && IS_IOS_DEVICE.test(navigator.userAgent)) {
      toast.error('You need to enable permissions to capture the answers');
      setTimeout(() => {
        window.location.reload();
      }, 500);

      return;
    }

    setCaptureState((prevState) => ({ ...prevState, loading: true }));
    if (captureState.timeoutId) {
      clearTimeout(captureState.timeoutId);
    }

    if (webcamRef.current) {
      const imageSrc = webcamRef.current.getScreenshot();
      if (imageSrc) {
        try {
          const { evaluationId, questionId, practiceSheetId, studentId } = params;

          logInfo(
            `Practice sheet capture for the evaluationId -> ${evaluationId}, practiceSheetId -> ${practiceSheetId} , studentId -> ${studentId} , questionId -> ${questionId} `,
            {
              evaluationId,
              practiceSheetId,
              studentId,
              questionId,
            },
            LOG_TYPE.INFO,
          );

          const imageId = Date.now();
          const fileName = `${questionId}_${studentId}_${imageId}.png`;
          const originImageObj = dataURLtoFile(imageSrc, fileName);

          const formData = new FormData();
          formData.append('images', originImageObj);
          formData.append('evaluationId', evaluationId || '');
          formData.append('practiceSheetId', practiceSheetId || '');
          if (noteId && noteId !== 0) {
            formData.append('noteId', noteId?.toString());
          } else {
            formData.append('page', pages.toString());
          }

          const questionFilesMap = {
            [questionId || '']: [fileName],
          };
          formData.append('questionImagesMap', JSON.stringify(questionFilesMap));

          const timeout = setTimeout(() => {
            setCaptureState({ loading: false, timeoutId: null, isTimeOut: true });
            toast.error('Capture timeout. Please try again.');
          }, 15000);

          setCaptureState((prevState) => ({ ...prevState, timeoutId: timeout }));

          addPracticeCaptures({ formData })
            .unwrap()
            .then((response) => {
              clearTimeout(timeout);
              const imageUrls = response?.data?.imageUrls;
              if (Array.isArray(imageUrls)) {
                setCapturedImage(imageUrls);
                setIsOpenModal(true);
              }
              setCaptureState({ loading: false, timeoutId: null, isTimeOut: false });
              logInfo(
                `Successfully captured the practice sheet for evaluationId: ${evaluationId}, practiceSheetId: ${practiceSheetId}, studentId: ${studentId}, questionId: ${questionId}`,
                {
                  evaluationId,
                  practiceSheetId,
                  studentId,
                  questionId,
                },
                LOG_TYPE.INFO,
              );
              getNoteBookCapture();
            })
            .catch((error) => {
              clearTimeout(timeout);
              if (error?.data?.data?.isAlreadySubmitted) {
                toast.error('Your practice paper has already been submitted.');
                setTimeout(() => {
                  navigate(STUDENT_PATHS.STUDENT_PRACTICE, {
                    replace: true,
                  });
                }, 1000);
              } else if (error.status === 'FETCH_ERROR') {
                toast.error(
                  'Network error occurred. Please check your internet connection and try again.',
                );
              } else {
                toast.error(error.data?.message);
              }
              setCaptureState({ loading: false, timeoutId: null, isTimeOut: false });
            });
        } catch (err) {
          clearTimeout(captureState.timeoutId || undefined);
          toast.error(err?.toString() || '');
          setCaptureState({ loading: false, timeoutId: null, isTimeOut: false });
        }
      }
    } else {
      setCaptureState({ loading: false, timeoutId: null, isTimeOut: false });
    }
  }, [
    captureState.timeoutId,
    params,
    logInfo,
    noteId,
    addPracticeCaptures,
    pages,
    getNoteBookCapture,
    navigate,
    useWebcam,
  ]);

  /* Check for the audio and video permission */
  useEffect(() => {
    if (!showQuestion && useWebcam) {
      fetchMediaPermissions();

      listenToMediaPermissions(({ videoPermissionDenied }) => {
        setVideoPermissionDenied(videoPermissionDenied);
      });

      if (!videoPermissionDenied) {
        setCaptureState((prevState) => ({ ...prevState, loading: false, isTimeOut: false }));
        webcamRef.current?.render;
      }
    }
  }, [refreshWebcam, showQuestion, videoPermissionDenied, fetchMediaPermissions, useWebcam]);

  const handleRecapture = () => {
    setIsOpenModal(false);
    setIsRecapture(true);
    setCapturedImage([]);
  };

  const handleDone = () => {
    const urlParams = new URLSearchParams(location.search);
    urlParams.delete('noteId');
    setNoteId(0);

    navigate(`${location.pathname}?${urlParams.toString()}`, { replace: true });
    setIsOpenModal(false);
    setShowQuestion(true);
    setIsRecapture(false);
    refetch();

    if (selectedQuestionIndex < questionData?.length - 1) {
      handleQrCodeCapture(questionData[selectedQuestionIndex + 1], selectedQuestionIndex + 1);
      toast.success('Redirected to the next question.');
      setTimeout(() => {
        globalThis.scrollTo({ top: 0, behavior: 'smooth' });
      }, 0);
    }
  };

  return (
    <div className='w-full min-h-screen p-3 bg-white md:p-6'>
      <button
        onClick={() => setShowQuestion(true)}
        className='flex items-center gap-2 px-4 py-2 mb-4 text-sm text-white rounded-full shadow-md bg-primary-500 sm:text-base hover:bg-primary-600 focus:outline-none'
      >
        <FiArrowLeft className='w-4 h-4' />
        <span>Back</span>
      </button>

      <div className='p-3 mb-4 text-base font-medium break-words shadow-sm bg-gray-50 rounded-xl'>
        <PortableTextWithLatex value={selectedQuestionData || []} />
      </div>

      {!useWebcam ? (
        <div ref={scrollRef} className='relative flex flex-col w-full min-h-[60vh] md:min-h-[70vh]'>
          {/* Hidden file input for native camera capture */}
          <input
            type='file'
            accept='image/*'
            capture={facingMode}
            ref={fileInputRef}
            onChange={handleNativeCapture}
            className='hidden'
          />

          <div className='flex flex-col items-center justify-center h-full pt-8 pb-12 bg-gray-50 rounded-xl'>
            <h3 className='mb-2 text-xl font-bold text-primary-600'>Take a photo</h3>
            <p className='mb-8 text-sm text-gray-500'>Tap to open camera</p>

            <button
              onClick={capture}
              disabled={captureState?.loading}
              className={`relative flex items-center justify-center w-20 h-20 mx-auto rounded-full ${captureState?.loading ? 'bg-gray-400' : 'bg-green-500 hover:bg-green-600'} shadow-lg transition-all`}
              aria-label={captureState?.isTimeOut ? 'Retry Capture' : 'Capture'}
            >
              {captureState?.loading ? (
                <ImSpinner8 className='w-8 h-8 text-white animate-spin' />
              ) : (
                <FiCamera className='w-10 h-10 text-white' />
              )}
            </button>

            <div className='flex items-center justify-center gap-4 mt-12'>
              <button
                onClick={switchToWebcam}
                className='flex items-center px-5 py-2 text-sm text-gray-700 bg-white border border-gray-200 rounded-full shadow-sm hover:bg-gray-50'
              >
                <FiVideo className='w-4 h-4 mr-2' />
                <span>Camera Mode</span>
              </button>

              <button
                onClick={switchCamera}
                className='flex items-center px-5 py-2 text-sm text-gray-700 bg-white border border-gray-200 rounded-full shadow-sm hover:bg-gray-50'
              >
                <FiRefreshCw className='w-4 h-4 mr-2' />
                <span>Switch Camera</span>
              </button>
            </div>

            <p className='mt-4 text-xs text-center text-gray-400'>
              Can't access camera? Try Camera Mode
            </p>
          </div>
        </div>
      ) : (
        <div ref={scrollRef} className='relative flex flex-col w-full h-[80vh]'>
          <Webcam
            audio={false}
            ref={webcamRef}
            imageSmoothing={true}
            muted={true}
            playsInline={true}
            screenshotFormat='image/png'
            className='absolute inset-0 z-0 object-cover w-full h-full rounded-xl'
            videoConstraints={{ facingMode }}
          />
          <div className='absolute inset-0 z-20 flex flex-col items-center justify-end h-full bg-gradient-to-t from-black/80 via-black/20 to-transparent'>
            <div className='w-full p-6'>
              <button
                onClick={capture}
                disabled={captureState?.loading || videoPermissionDenied}
                className={`relative flex items-center justify-center w-20 h-20 mx-auto mb-6 rounded-full ${captureState?.loading || videoPermissionDenied ? 'bg-gray-400' : 'bg-green-500 hover:bg-green-600'} shadow-lg transition-all`}
                aria-label={captureState?.isTimeOut ? 'Retry Capture' : 'Capture'}
              >
                {captureState?.loading ? (
                  <ImSpinner8 className='w-8 h-8 text-white animate-spin' />
                ) : (
                  <FiCamera className='w-10 h-10 text-white' />
                )}
                {videoPermissionDenied && (
                  <div
                    className='absolute z-10 cursor-pointer -top-2 -right-2 animate-pulse hover:animate-none'
                    onClick={(e) => {
                      e.stopPropagation();
                      setIsModalOpen(true);
                    }}
                  >
                    <span className='flex items-center justify-center w-6 h-6 text-sm font-bold text-white rounded-full bg-semantic-orange-500 hover:ring-4 hover:ring-orange-300/50'>
                      !
                    </span>
                  </div>
                )}
              </button>

              {/* Only show camera controls if permissions are granted */}
              <div className='flex flex-wrap items-center justify-center gap-3 mb-4'>
                {!videoPermissionDenied ? (
                  <>
                    <button
                      onClick={switchCamera}
                      className='flex items-center px-4 py-2 text-sm text-white rounded-full shadow-md bg-primary-500/80 backdrop-blur-sm hover:bg-primary-600'
                    >
                      <FiRefreshCw className='w-4 h-4 mr-2' />
                      <span>Switch Camera</span>
                    </button>

                    <button
                      onClick={() => setUseWebcam(false)}
                      className='flex items-center px-4 py-2 text-sm text-white rounded-full shadow-md bg-neutral-600/80 backdrop-blur-sm hover:bg-neutral-700'
                    >
                      <FiSmartphone className='w-4 h-4 mr-2' />
                      <span>Camera</span>
                    </button>
                  </>
                ) : (
                  <button
                    onClick={() => setIsModalOpen(true)}
                    className='flex items-center px-4 py-2 text-sm text-white rounded-full shadow-md bg-red-500/90 backdrop-blur-sm hover:bg-red-600'
                  >
                    <span>Enable Camera Access</span>
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      )}

      {isOpenModal && (
        <div>
          {isMobileScreen ? (
            <ModalBottomSheet
              setOpenModal={setIsOpenModal}
              openModal={isOpenModal}
              showCloseBtn={true}
              heading={evalName}
              children={
                <>
                  <ImageModal
                    ImageData={capturedImage}
                    onRecapture={handleRecapture}
                    selectedQuestionIndex={selectedQuestionIndex}
                    questionData={selectedQuestionData || []}
                    totalQuestionsLength={questionData.length}
                    onDone={handleDone}
                  />
                </>
              }
            />
          ) : (
            <Modal
              showCloseBtn={true}
              openModal={isOpenModal}
              setOpenModal={setIsOpenModal}
              heading={evalName}
              size={'5xl'}
              children={
                <>
                  <ImageModal
                    ImageData={capturedImage}
                    onRecapture={handleRecapture}
                    selectedQuestionIndex={selectedQuestionIndex}
                    questionData={selectedQuestionData || []}
                    totalQuestionsLength={questionData.length}
                    onDone={handleDone}
                  />
                </>
              }
            />
          )}
        </div>
      )}

      {videoPermissionDenied && useWebcam && (
        <div>
          {isMobileScreen ? (
            <ModalBottomSheet
              openModal={isModalOpen}
              setOpenModal={setIsModalOpen}
              showCloseBtn={false}
              children={
                <div className='flex flex-col gap-4 h-[80vh]'>
                  <PracticePermissionModal setIsModalOpen={setIsModalOpen} />
                </div>
              }
            />
          ) : (
            <Modal
              openModal={isModalOpen}
              setOpenModal={setIsModalOpen}
              showCloseBtn={false}
              children={<PracticePermissionModal setIsModalOpen={setIsModalOpen} />}
            />
          )}
        </div>
      )}
    </div>
  );
}
