import { MediaPermissionIcon } from 'assets/svg';
import { Button } from 'components/common';
import { IMediaPermission } from 'types';

export default function MediaPermission({ setIsModalOpen }: IMediaPermission) {
  return (
    <div className='max-w-2xl p-6 mx-auto bg-white shadow-sm rounded-xl'>
      {/* Header with close button */}
      <div className='flex items-center justify-between pb-4 mb-6 border-b border-gray-100'>
        <div className='flex items-center gap-3'>
          <div className='flex items-center justify-center w-10 h-10 rounded-full bg-red-50'>
            <svg className='w-5 h-5 text-red-500' fill='none' viewBox='0 0 24 24' stroke='currentColor'>
              <path strokeLinecap='round' strokeLinejoin='round' strokeWidth={2} d='M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z' />
            </svg>
          </div>
          <h1 className='text-xl font-semibold text-gray-800'>Camera Access Needed</h1>
        </div>
        
        <button 
          onClick={() => setIsModalOpen(false)}
          className='flex items-center justify-center w-8 h-8 text-gray-400 transition-colors rounded-full hover:text-gray-600 hover:bg-gray-100 focus:outline-none'
          aria-label='Close'
        >
          <svg className='w-5 h-5' fill='none' viewBox='0 0 24 24' stroke='currentColor'>
            <path strokeLinecap='round' strokeLinejoin='round' strokeWidth={2} d='M6 18L18 6M6 6l12 12' />
          </svg>
        </button>
      </div>

      <div className='flex flex-col gap-6 mb-8 md:flex-row'>
        {/* Left side - Image */}
        <div className='flex justify-center w-full md:w-1/2'>
          <img
            src={MediaPermissionIcon}
            alt='Browser camera permission illustration'
            className='object-contain w-auto max-h-64'
          />
        </div>

        {/* Right side - Instructions */}
        <div className='flex flex-col justify-center w-full md:w-1/2'>
          <h2 className='mb-4 text-lg font-medium text-gray-800'>
            Your camera access is disabled
          </h2>
          
          <div className='space-y-4'>
            <div className='flex gap-3'>
              <div className='flex items-center justify-center flex-shrink-0 bg-blue-100 rounded-full w-7 h-7'>
                <span className='font-medium text-blue-600'>1</span>
              </div>
              <div>
                <p className='text-gray-700'>Look for the <span className='px-1 py-0.5 bg-gray-100 rounded text-gray-800 font-medium'>🔒</span> or <span className='px-1 py-0.5 bg-gray-100 rounded text-gray-800 font-medium'>📷</span> icon in your browser's address bar</p>
              </div>
            </div>
            
            <div className='flex gap-3'>
              <div className='flex items-center justify-center flex-shrink-0 bg-blue-100 rounded-full w-7 h-7'>
                <span className='font-medium text-blue-600'>2</span>
              </div>
              <div>
                <p className='text-gray-700'>Click it and select <span className='font-medium text-blue-600'>Allow</span> for camera access</p>
              </div>
            </div>
            
            <div className='flex gap-3'>
              <div className='flex items-center justify-center flex-shrink-0 bg-blue-100 rounded-full w-7 h-7'>
                <span className='font-medium text-blue-600'>3</span>
              </div>
              <div>
                <p className='text-gray-700'>Refresh this page using the button below</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Action buttons */}
      <div className='flex flex-col justify-end gap-3 pt-4 border-t border-gray-100 sm:flex-row'>
        <Button
          className='px-5 text-gray-700 transition-colors bg-gray-100 rounded-lg hover:bg-gray-200'
          onClick={() => setIsModalOpen(false)}
          size={'sm'}
          text='Close'
        />
        <button
          className='flex items-center justify-center gap-2 px-5 py-1 md:py-[1px] text-white transition-colors bg-blue-600 rounded-lg hover:bg-blue-700'
          onClick={() => window.location.reload()}
        >
          <span>Refresh Page</span>
        </button>
      </div>
    </div>
  );
}
