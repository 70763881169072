import { Pagination, Tooltip } from 'flowbite-react';
import Cookie from 'js-cookie';
import { isEmpty } from 'lodash';
import moment from 'moment';
import { useState } from 'react';
import { FaCheckCircle } from 'react-icons/fa';
import { HiClock, HiDocument, HiInformationCircle } from 'react-icons/hi';
import { useNavigate, useParams } from 'react-router-dom';

import { useGetUserDataQuery } from 'store/apiSlices/login.apiSlice';
import { useGetPracticeSheetReportQuery } from 'store/apiSlices/studentDashboard.apiSlice';

import { BouncingDotsLoader } from 'components/common';

import {
  CONFIG,
  EMPTY_PRACTICE_PAPER,
  EVALUATED_STATUS,
  EVALUATION_SUBMISSION_STATUS,
  LOG_TYPE,
  ROUTES,
} from 'configs';
import { useLogger, useWindowDimensions } from 'hooks';
import { DateFilterOption, IHandleStartEvaluation } from 'types';
import { DateRange } from 'utils';
import { DateRange as DateRangeType, isMobileOrTablet } from 'utils';
import PracticePapers from '../PracticePapers';
import MobileCard from './MobileCard';

const PracticeDashboard = ({
  getDateFilterParams,
  dateFilter,
  dateRange,
  selectedSubjectId,
}: {
  getDateFilterParams: (
    dateFilter: DateFilterOption,
    dateRange?: DateRangeType,
    timeField?: 'meetingEndTime' | 'assignedOn',
  ) => Record<string, unknown>;
  dateFilter: DateFilterOption;
  dateRange: DateRange;
  selectedSubjectId: string;
}) => {
  const navigate = useNavigate();
  const { logInfo } = useLogger();
  const { isMobileScreen } = useWindowDimensions();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [filter, setFilter] = useState<{
    notSubmitted: boolean;
    submitted: boolean;
    all: boolean;
  }>({
    notSubmitted: false,
    submitted: false,
    all: true,
  });
  const [showData, setShowData] = useState<{
    practiceSheetId: string;
    evaluationId: string;
    isShowTheQuestion: boolean;
    evaluationStatus: string;
  }>({
    practiceSheetId: '',
    evaluationId: '',
    isShowTheQuestion: false,
    evaluationStatus: '',
  });
  const { studentId: paramStudentId } = useParams();
  const { data: profileData } = useGetUserDataQuery(undefined, {
    refetchOnMountOrArgChange: true,
  });

  const {
    data: practiceReportResponse,
    isLoading,
    isFetching,
  } = useGetPracticeSheetReportQuery(
    {
      studentId: paramStudentId || profileData?.data?.id || '',
      page: currentPage,
      pageSize: 10,
      filter: {
        ...getDateFilterParams(dateFilter, dateRange, 'assignedOn'),
        ...(filter.submitted && {
          studentSubmissionStatus: {
            $in: [
              EVALUATION_SUBMISSION_STATUS.SUBMITTED_WITHIN_DEADLINE,
              EVALUATION_SUBMISSION_STATUS.SUBMITTED_POST_DEADLINE,
              EVALUATION_SUBMISSION_STATUS.CORRECT_SUBMISSION,
            ],
          },
        }),
        ...(filter.notSubmitted && {
          studentSubmissionStatus: {
            $in: [
              EVALUATION_SUBMISSION_STATUS.WRONG_SUBMISSION,
              EVALUATION_SUBMISSION_STATUS.NOT_SUBMITTED,
              EVALUATION_SUBMISSION_STATUS.PARTIAL_SUBMISSION,
              EVALUATION_SUBMISSION_STATUS.BLANK_SUBMISSION,
            ],
          },
        }),
        ...(profileData?.data?.gradeSanityId && {
          gradeId: {
            $eq: profileData?.data?.gradeSanityId,
          },
        }),
        ...(selectedSubjectId && {
          subjectId: {
            $eq: selectedSubjectId,
          },
        }),
      },
    },
    {
      skip: !paramStudentId && !profileData?.data?.id,
      refetchOnMountOrArgChange: true,
    },
  );

  /* Handle Start evaluation is based on the device */
  const handleStartBasedOntheMobileDevice = async ({
    practiceSheetId,
    evaluationId,
    studentId,
    evaluationStatus,
  }: IHandleStartEvaluation) => {
    try {
      logInfo(
        'Started handling evaluation initiation based on device type',
        { practiceSheetId, evaluationId },
        LOG_TYPE.INFO,
      );
      const result = await isMobileOrTablet();

      logInfo('Device check completed: Mobile or Tablet detected', { result }, LOG_TYPE.INFO);
      if (result && evaluationStatus !== EVALUATED_STATUS.EVALUATED) {
        const token = Cookie.get(`${CONFIG.VITE_BASE_DOMAIN_ENV}CoachToken`);
        const encodedToken = token ? btoa(JSON.stringify(token)) : '';
        const queryParams = new URLSearchParams({
          evaluationId: evaluationId,
          practiceSheetId: practiceSheetId,
          questionId: '',
          studentId: String(studentId),
          token: encodedToken,
          showQuestion: 'true',
        });
        logInfo('Navigating to mobile capture route', { queryParams }, LOG_TYPE.INFO);
        navigate(`${ROUTES.PRACTICE_CLASS_QRCODE_CAPTURE}?${queryParams.toString()}`);
      } else {
        logInfo(
          'Setting up data for non-mobile device',
          { practiceSheetId, evaluationId, isShowTheQuestion: true },
          LOG_TYPE.INFO,
        );
        setShowData({
          practiceSheetId,
          evaluationId,
          isShowTheQuestion: true,
          evaluationStatus: evaluationStatus || '',
        });
      }
    } catch (error) {
      logInfo('Error encountered during evaluation initiation', { error }, LOG_TYPE.ERROR);
    }
  };

  const getStatusStyle = (status: string) => {
    switch (status) {
      case EVALUATION_SUBMISSION_STATUS.CORRECT_SUBMISSION:
      case EVALUATION_SUBMISSION_STATUS.SUBMITTED_WITHIN_DEADLINE:
      case EVALUATED_STATUS.EVALUATED:
      case EVALUATION_SUBMISSION_STATUS.SUBMITTED_POST_DEADLINE:
        return 'bg-semantic-green-100 text-semantic-green-800';
      case EVALUATION_SUBMISSION_STATUS.NOT_SUBMITTED:
      case EVALUATED_STATUS.NOT_EVALUATED:
        return 'bg-semantic-orange-100 text-semantic-orange-800';
      default:
        return 'bg-semantic-orange-100 text-semantic-orange-800';
    }
  };

  // Modified stats array to be used in both mobile and desktop views
  const stats = [
    {
      icon: <HiDocument className='w-5 h-5 text-primary-600' />,
      title: 'Total Papers',
      count: practiceReportResponse?.totalPracticeSheetsCount || 0,
      isActive: filter.all,
      onClick: () => setFilter({ notSubmitted: false, submitted: false, all: true }),
      activeRing: 'ring-primary-500',
      activeBg: 'bg-primary-100',
      countColor: 'text-gray-900',
    },
    {
      icon: <FaCheckCircle className='w-5 h-5 text-semantic-green-600' />,
      title: 'Submitted',
      count: practiceReportResponse?.totalPracticeSheetSubmitted || 0,
      isActive: filter.submitted,
      onClick: () => setFilter({ submitted: true, notSubmitted: false, all: false }),
      activeRing: 'ring-semantic-green-500',
      activeBg: 'bg-semantic-green-100',
      countColor: 'text-semantic-green-600',
    },
    {
      icon: <HiClock className='w-5 h-5 text-semantic-orange-600' />,
      title: 'Not Submitted',
      count: practiceReportResponse?.totalPracticeSheetNotSubmitted || 0,
      isActive: filter.notSubmitted,
      onClick: () => setFilter({ notSubmitted: true, submitted: false, all: false }),
      activeRing: 'ring-semantic-orange-500',
      activeBg: 'bg-semantic-orange-100',
      countColor: 'text-semantic-orange-600',
    },
  ];

  return (
    <>
      <div className='bg-primary-50'>
        <div className='px-4 py-6 mx-auto max-w-7xl sm:px-6 lg:px-8'>
          {/* Mobile Stats Overview - Horizontal Scrolling */}
          {isMobileScreen ? (
            <div className='mb-3'>
              <div className='flex justify-between gap-2'>
                {stats.map((stat, index) => (
                  <div
                    key={index + stat?.title}
                    className={`flex-1 p-2 transition-all duration-200 bg-white rounded-lg ${
                      stat.isActive
                        ? `ring-2 ${stat.activeRing} shadow-sm`
                        : 'border border-gray-100'
                    }`}
                    onClick={stat.onClick}
                  >
                    <div className='flex flex-col items-center text-center'>
                      <div
                        className={`p-1 rounded-lg ${stat.isActive ? stat.activeBg : 'bg-gray-100'}`}
                      >
                        {stat.icon}
                      </div>
                      <p className='mt-0.5 text-[10px] font-medium text-gray-500'>{stat.title}</p>
                      <p className={`text-sm font-bold ${stat.countColor}`}>{stat.count}</p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ) : (
            /* Desktop Stats Overview - Grid Layout */
            <div className='grid grid-cols-1 gap-4 mb-6 sm:grid-cols-3 font-poppins'>
              {stats.map((stat, index) => (
                <div
                  key={index}
                  className={`p-4 transition-all duration-200 bg-white rounded-lg hover:cursor-pointer ${
                    stat.isActive
                      ? `ring-2 ${stat.activeRing} shadow-md`
                      : 'hover:bg-gray-50 border border-gray-100'
                  }`}
                  onClick={stat.onClick}
                >
                  <div className='flex items-center gap-4'>
                    <div
                      className={`p-2 rounded-lg ${stat.isActive ? stat.activeBg : 'bg-gray-100'}`}
                    >
                      {stat.icon}
                    </div>
                    <div>
                      <p className='text-sm font-medium text-gray-500'>{stat.title}</p>
                      <p className={`text-2xl font-bold ${stat.countColor}`}>{stat.count}</p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}

          {isLoading || isFetching ? (
            <div className='flex items-center justify-center h-48'>
              <BouncingDotsLoader />
            </div>
          ) : isEmpty(practiceReportResponse?.docs) ? (
            <div className='p-8 text-center bg-white rounded-lg shadow-sm'>
              <p className='text-lg font-medium text-gray-500'>{EMPTY_PRACTICE_PAPER}</p>
            </div>
          ) : (
            <div className='space-y-4'>
              {/* Conditional rendering based on screen size */}
              {isMobileScreen ? (
                <div className='space-y-4'>
                  {practiceReportResponse?.docs?.map((paper) => (
                    <MobileCard
                      key={paper.practiceSheetId}
                      paper={paper}
                      onStartPractice={handleStartBasedOntheMobileDevice}
                    />
                  ))}
                </div>
              ) : (
                <div className='overflow-hidden bg-white rounded-lg shadow-sm'>
                  <div className='p-6'>
                    <div className='overflow-x-auto'>
                      <table className='w-full'>
                        <thead className='font-sora'>
                          <tr className='bg-gray-50'>
                            <th className='px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase'>
                              Practice Paper
                            </th>
                            <th className='px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase'>
                              Assignment Date
                            </th>
                            <th className='px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase'>
                              Subject
                            </th>
                            <th className='px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase'>
                              Submission Status
                            </th>
                            <th className='px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase'>
                              Evaluation Status
                            </th>
                            <th className='px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase'>
                              Evaluation Result
                            </th>
                            <th className='px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase'>
                              {/* //INFO: FOR UI PURPOSE KEEP THIS HEADER EMPTY */}
                            </th>
                          </tr>
                        </thead>
                        <tbody className='divide-y divide-gray-200 font-poppins'>
                          {practiceReportResponse?.docs?.[0]?.noOfPracticeSheets === 0 ? (
                            <tr>
                              <td colSpan={6} className='px-6 py-8 text-center'>
                                <div className='flex items-center justify-center'>
                                  <span className='px-4 py-2 text-sm font-medium text-gray-900 bg-gray-100 border border-gray-200 rounded-full'>
                                    No practice sheets found!
                                  </span>
                                </div>
                              </td>
                            </tr>
                          ) : (
                            <>
                              {practiceReportResponse?.docs?.map((paper, index) => (
                                <tr key={index} className='hover:bg-gray-50'>
                                  <td className='px-6 py-4 text-sm font-medium max-w-[200px] text-ellipsis overflow-hidden text-gray-900'>
                                    {paper?.practiceSheetName}
                                  </td>
                                  <td className='px-6 py-4 text-sm text-gray-500 whitespace-nowrap'>
                                    {moment(paper?.assignedOn).format('ll')}
                                  </td>
                                  <td className='px-6 py-4 text-sm text-gray-500 whitespace-nowrap'>
                                    {paper?.subjectName}
                                  </td>
                                  <td className='px-6 py-4 whitespace-nowrap'>
                                    <span
                                      className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${getStatusStyle(
                                        paper?.studentSubmissionStatus,
                                      )}`}
                                    >
                                      {[
                                        EVALUATION_SUBMISSION_STATUS.CORRECT_SUBMISSION,
                                        EVALUATION_SUBMISSION_STATUS.SUBMITTED_WITHIN_DEADLINE,
                                        EVALUATION_SUBMISSION_STATUS.SUBMITTED_POST_DEADLINE,
                                      ].includes(paper?.studentSubmissionStatus)
                                        ? 'Submitted'
                                        : 'Not Submitted'}
                                    </span>
                                  </td>
                                  <td className='px-6 py-4 whitespace-nowrap'>
                                    {paper?.studentSubmissionStatus ===
                                    EVALUATION_SUBMISSION_STATUS.NOT_SUBMITTED ? (
                                      <span className='text-sm font-medium text-gray-900'>-</span>
                                    ) : (
                                      <span
                                        className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${getStatusStyle(
                                          paper?.evaluationStatus,
                                        )}`}
                                      >
                                        {paper?.evaluationStatus === EVALUATED_STATUS.EVALUATED
                                          ? 'Evaluated'
                                          : 'Evaluation In-progress'}
                                      </span>
                                    )}
                                  </td>
                                  <td className='px-6 py-4 whitespace-nowrap'>
                                    <div className='flex items-center gap-2'>
                                      {paper?.evaluationStatus ===
                                      EVALUATED_STATUS.NOT_EVALUATED ? (
                                        <span className='text-sm font-medium text-gray-900'>-</span>
                                      ) : (
                                        <>
                                          <span className='text-sm font-medium text-gray-900'>
                                            {paper?.totalQuestions}/{paper?.totalQuestionsAttempted}
                                            /{paper?.totalQuestionsCorrect}
                                          </span>
                                          <span className='px-2 py-0.5 text-xs font-medium text-green-800 bg-green-100 rounded-full'>
                                            {paper?.totalQuestionsCorrectPercentage}
                                          </span>
                                          <Tooltip
                                            content={
                                              <span className='capitalize whitespace-pre-wrap'>
                                                Total Questions / Total Attempted (Correct +
                                                Incorrect) / Total Correct (Percentage)
                                              </span>
                                            }
                                            placement='top'
                                            className='text-xs md:text-sm'
                                          >
                                            <HiInformationCircle className='w-4 h-4 text-gray-400' />
                                          </Tooltip>
                                        </>
                                      )}
                                    </div>
                                  </td>
                                  <td className='px-6 py-4 text-sm text-blue-600 whitespace-nowrap'>
                                    {/* //TODO: HAVE TO CHECK WITH THIS SENTENCE */}
                                    {[
                                      EVALUATION_SUBMISSION_STATUS.SUBMITTED_WITHIN_DEADLINE,
                                      EVALUATION_SUBMISSION_STATUS.SUBMITTED_POST_DEADLINE,
                                      EVALUATION_SUBMISSION_STATUS.CORRECT_SUBMISSION,
                                    ].includes(paper?.studentSubmissionStatus) &&
                                    paper?.evaluationStatus === EVALUATED_STATUS.NOT_EVALUATED ? (
                                      <div className='flex flex-col items-start gap-2'>
                                        <div className='flex items-center gap-2 px-3 py-2 text-sm rounded-full text-amber-700 bg-amber-50'>
                                          <HiInformationCircle className='w-5 h-5 text-amber-500' />
                                          <span className='font-medium'>
                                            Evaluation in progress
                                          </span>
                                        </div>
                                        <div className='text-xs italic text-gray-500'>
                                          Answers will be visible post evaluation
                                        </div>
                                      </div>
                                    ) : (
                                      <button
                                        onClick={() => {
                                          handleStartBasedOntheMobileDevice({
                                            practiceSheetId: paper?.practiceSheetId,
                                            evaluationId: paper?.evaluationId,
                                            studentId: paper?.studentId,
                                            evaluationStatus: paper?.evaluationStatus,
                                          });
                                        }}
                                        className={`
                                          inline-flex items-center justify-center
                                          px-4 py-2
                                          text-sm font-medium
                                          text-white
                                          border border-transparent
                                          rounded-lg
                                          capitalize
                                          transition-all duration-200
                                          shadow-sm
                                          focus:outline-none focus:ring-2 focus:ring-offset-2
                                          ${
                                            paper?.evaluationStatus === EVALUATED_STATUS.EVALUATED
                                              ? 'bg-semantic-green-500 hover:bg-semantic-green-600 focus:ring-semantic-green-500'
                                              : 'bg-primary-500 hover:bg-primary-600 focus:ring-primary-500'
                                          }
                                        `}
                                      >
                                        {paper?.evaluationStatus === EVALUATED_STATUS.EVALUATED ? (
                                          <span>View Answers</span>
                                        ) : (
                                          <span>Start Practice</span>
                                        )}
                                      </button>
                                    )}
                                  </td>
                                </tr>
                              ))}
                            </>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              )}

              {/* Pagination - Modified to be more compact on mobile */}
              {practiceReportResponse?.totalPages && practiceReportResponse?.totalPages > 1 && (
                <div
                  className={`flex flex-col items-center justify-center gap-2 py-4 px-3 bg-white border-t rounded-lg ${isMobileScreen ? 'py-3' : 'px-6 py-8'}`}
                >
                  <Pagination
                    currentPage={currentPage}
                    totalPages={practiceReportResponse?.totalPages || 1}
                    onPageChange={(page) => setCurrentPage(page)}
                    showIcons
                    layout={isMobileScreen ? 'navigation' : 'pagination'}
                    // Remove the size prop and use CSS classes instead
                    className={isMobileScreen ? 'text-xs' : 'text-sm'}
                  />
                </div>
              )}
            </div>
          )}
        </div>
      </div>
      {/* Modal for Viewing the Solution */}
      {showData?.evaluationId && showData?.isShowTheQuestion && (
        <PracticePapers
          evaluationId={showData?.evaluationId}
          practiceSheetId={showData?.practiceSheetId}
          setShowData={() =>
            setShowData({
              practiceSheetId: '',
              evaluationId: '',
              isShowTheQuestion: false,
              evaluationStatus: '',
            })
          }
          submitted={showData?.evaluationStatus === EVALUATED_STATUS.EVALUATED ? true : false}
        />
      )}
    </>
  );
};

export default PracticeDashboard;
