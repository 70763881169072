import { isEmpty } from 'lodash';
import moment from 'moment';
import { useCallback, useEffect, useState, useRef } from 'react';
import { useGetUserDataQuery } from 'store/apiSlices/login.apiSlice';
import { useGetPracticeSheetDetailsQuery } from 'store/apiSlices/studentDashboard.apiSlice';
import { SectionHeadingLogo } from 'assets/svg';
import { BouncingDotsLoader, DateFilter, Footer, PaginationComp } from 'components/common';
import { AllPracticeSheets, DashboardNav, PracticeDashboard } from 'components/screens';
import { useAppSelector } from 'hooks/store';
import { HiChevronDown, HiFilter, HiX } from 'react-icons/hi';

import { EMPTY_PRACTICE_PAPER, EVALUATION_SUBMISSION_STATUS, USER_TYPE } from 'configs';
import { DateFilterOption, IPracticeDoc } from 'types';
import { selectUserType } from 'store/slice/studentRegister.slice';
import CustomTabs from 'components/common/Tabs/CustomTabs';
import { useParams } from 'react-router-dom';
import { useGetSiblingParentListDetailsQuery } from 'store/apiSlices/parentDashboard.apiSlice';

import ZohoSalesIQ from 'components/common/ChatBot/initiateZohoWidget';
import useChatDetails from 'components/common/ChatBot/ChatBotDetails';
import { getDateFilterParams } from 'utils';
import { SANITY_EPIC_SEARCH_NAMES } from 'configs/common';
import { useSanityOptions } from 'hooks/useSanityOptions';
import { useWindowDimensions } from 'hooks';

export default function Practice() {
  moment.tz.setDefault('Asia/Kolkata');
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [isPageLoading, setIsPageLoading] = useState<boolean>(false);
  const [allPracticeSheets, setAllPracticeSheets] = useState<IPracticeDoc[]>([]);
  const [allNotSubmittedPracticeSheets, setNotSubmittedAllPracticeSheets] = useState<
    IPracticeDoc[]
  >([]);
  const { studentId } = useParams();
  const { isMobileScreen } = useWindowDimensions();
  const { data: profileData } = useGetUserDataQuery(undefined, { refetchOnMountOrArgChange: true });
  const userType = useAppSelector(selectUserType);

  const [dateFilter, setDateFilter] = useState<DateFilterOption>('last_30_days');
  const [dateRange, setDateRange] = useState<Date[]>([]);
  const [showDashboard, setShowDashboard] = useState<boolean>(true);
  const [submittedState, setSubmittedState] = useState<boolean | null>(null);
  const [selectedSubjectId, setSelectedSubjectId] = useState<string>('');
  const [isMobileFilterOpen, setIsMobileFilterOpen] = useState<boolean>(false);
  const [isSubjectDropdownOpen, setIsSubjectDropdownOpen] = useState<boolean>(false);
  const subjectDropdownRef = useRef<HTMLDivElement>(null);
  const mobileFilterRef = useRef<HTMLDivElement>(null);

  const { data: subjectsList } = useSanityOptions(SANITY_EPIC_SEARCH_NAMES.SUBJECTS);

  useEffect(() => {
    if (subjectsList?.docs) {
      setSelectedSubjectId('');
    }
  }, [subjectsList?.docs]);

  // Close the subject dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        subjectDropdownRef.current &&
        !subjectDropdownRef.current.contains(event.target as Node)
      ) {
        setIsSubjectDropdownOpen(false);
      }
      if (
        mobileFilterRef.current &&
        !mobileFilterRef.current.contains(event.target as Node) &&
        isMobileFilterOpen
      ) {
        setIsMobileFilterOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isMobileFilterOpen]);

  const { data: practiceSheet, isLoading: isNotSubmittedLoading } = useGetPracticeSheetDetailsQuery(
    {
      filter: {
        ...getDateFilterParams(dateFilter, dateRange, 'assignedOn'),
        studentSubmissionStatus: {
          $in: [
            EVALUATION_SUBMISSION_STATUS.NOT_SUBMITTED,
            EVALUATION_SUBMISSION_STATUS.BLANK_SUBMISSION,
            EVALUATION_SUBMISSION_STATUS.WRONG_SUBMISSION,
            EVALUATION_SUBMISSION_STATUS.PARTIAL_SUBMISSION,
          ],
        },
        ...(profileData?.data?.gradeSanityId && {
          gradeId: {
            $eq: profileData?.data?.gradeSanityId,
          },
        }),
        ...(selectedSubjectId && {
          subjectId: {
            $eq: selectedSubjectId,
          },
        }),
      },
      sort: {
        expiresOn: 1,
      },
      pageNumber,
      studentId,
    },
    {
      skip: !profileData?.data?.id && pageNumber > 1,
    },
  );
  const { data: parentSiblingsData } = useGetSiblingParentListDetailsQuery(undefined, {
    refetchOnMountOrArgChange: true,
    skip: userType === USER_TYPE.STUDENT,
  });

  const { data: practiceSheetSubmitted, isLoading: isSubmittedLoading } =
    useGetPracticeSheetDetailsQuery(
      {
        filter: {
          ...getDateFilterParams(dateFilter, dateRange, 'assignedOn'),
          studentSubmissionStatus: {
            $in: [
              EVALUATION_SUBMISSION_STATUS.SUBMITTED_WITHIN_DEADLINE,
              EVALUATION_SUBMISSION_STATUS.SUBMITTED_POST_DEADLINE,
              EVALUATION_SUBMISSION_STATUS.CORRECT_SUBMISSION,
            ],
          },
          ...(profileData?.data?.gradeSanityId && {
            gradeId: {
              $eq: profileData?.data?.gradeSanityId,
            },
          }),
          ...(selectedSubjectId && {
            subjectId: {
              $eq: selectedSubjectId,
            },
          }),
        },
        sort: {
          submittedOn: 1,
        },
        pageNumber,
        studentId,
      },
      {
        skip: !profileData?.data?.id || pageNumber === 0,
      },
    );
  useEffect(() => {
    setIsPageLoading(isSubmittedLoading);
  }, [isSubmittedLoading]);
  useEffect(() => {
    if (!isSubmittedLoading && practiceSheetSubmitted?.docs) {
      setAllPracticeSheets(practiceSheetSubmitted.docs);
    }
  }, [practiceSheetSubmitted, isSubmittedLoading]);
  useEffect(() => {
    if (!isNotSubmittedLoading && practiceSheet?.docs) {
      setNotSubmittedAllPracticeSheets(practiceSheet.docs);
    }
  }, [isNotSubmittedLoading, practiceSheet?.docs]);
  const sortedPracticeSheets: IPracticeDoc[] = allNotSubmittedPracticeSheets
    ? [...allNotSubmittedPracticeSheets].sort((a, b) =>
        moment(a.expiresOn).isBefore(b.expiresOn) ? -1 : 1,
      )
    : [];
  const handlePageChange = useCallback((page: number) => {
    setPageNumber(page);
  }, []);

  const chatBotDetails = useChatDetails();
  // Date filter options for mobile
  const dateFilterOptions: { value: DateFilterOption; label: string }[] = [
    { value: 'last_30_days', label: 'Last 30 Days' },
    { value: 'current_month', label: 'Current Month' },
    { value: 'past_month', label: 'Past Month' },
    { value: 'all', label: 'All' },
  ];

  return (
    <div className='flex flex-col justify-between min-h-screen bg-gray-100'>
      <DashboardNav type={userType || USER_TYPE?.STUDENT} parentSiblingsData={parentSiblingsData} />
      <div className='flex flex-col flex-1 py-3 mx-4 lg:mx-8'>
        <div className='flex flex-col justify-between w-full gap-4 md:flex-row md:items-center'>
          <h1 className='items-center py-4 text-xl font-bold tracking-wider uppercase md:flex font-sora text-start md:text-lg lg:text-2xl xl:leading-6'>
            <img src={SectionHeadingLogo} alt='uprio section logo' className='my-4 mr-5 md:my-0' />
            {'Practice Papers'}
          </h1>

          {subjectsList?.docs && (
            <>
              {/* Mobile: Subject Dropdown */}
              <div className='md:hidden' ref={subjectDropdownRef}>
                <button
                  onClick={() => setIsSubjectDropdownOpen(!isSubjectDropdownOpen)}
                  className='flex items-center justify-between w-full px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-lg'
                >
                  <span>
                    {selectedSubjectId
                      ? subjectsList.docs.find((s) => s._id === selectedSubjectId)?.name ||
                        'Select Subject'
                      : 'All Subjects'}
                  </span>
                  <HiChevronDown className='w-5 h-5 text-gray-500' />
                </button>

                {isSubjectDropdownOpen && (
                  <div className='absolute z-10 w-64 mt-1 overflow-auto bg-white rounded-md shadow-lg max-h-60'>
                    <div className='py-1'>
                      <button
                        onClick={() => {
                          setSelectedSubjectId('');
                          setIsSubjectDropdownOpen(false);
                          setPageNumber(1);
                        }}
                        className='w-full text-left px-4 py-2.5 text-xs md:text-sm hover:bg-gray-100'
                      >
                        All Subjects
                      </button>
                      {subjectsList.docs.map((subject) => (
                        <button
                          key={subject._id}
                          onClick={() => {
                            setSelectedSubjectId(subject._id);
                            setIsSubjectDropdownOpen(false);
                            setPageNumber(1);
                          }}
                          className='w-full text-left px-4 py-2.5 text-xs md:text-sm hover:bg-gray-100'
                        >
                          {subject.name}
                        </button>
                      ))}
                    </div>
                  </div>
                )}
              </div>

              {/* Desktop: Subject Pills */}
              <div className='items-center hidden gap-3 md:flex md:justify-end'>
                <span className='text-sm font-medium text-gray-600'>Subjects:</span>
                <div className='inline-flex p-1 rounded-lg bg-primary-100'>
                  <button
                    onClick={() => setSelectedSubjectId('')}
                    className={`px-4 py-1 text-sm font-medium transition-all duration-200 rounded-lg ${
                      selectedSubjectId === ''
                        ? 'bg-primary-500 text-white'
                        : 'text-gray-700 hover:bg-secondary-50'
                    }`}
                  >
                    All Subjects
                  </button>
                  {subjectsList.docs.map((subject) => (
                    <button
                      key={subject._id}
                      onClick={() => setSelectedSubjectId(subject._id)}
                      className={`px-4 py-1 text-sm font-medium transition-all duration-200 rounded-lg ${
                        selectedSubjectId === subject._id
                          ? 'bg-primary-500 text-white'
                          : 'text-gray-700 hover:bg-secondary-50'
                      }`}
                    >
                      {subject.name}
                    </button>
                  ))}
                </div>
              </div>
            </>
          )}
        </div>

        <div className='flex flex-col items-center justify-between gap-4 my-3 xl:flex-row font-sora'>
          {/* Mobile View: Compact Tabs */}
          <div className='w-full md:w-auto'>
            <div className='flex items-center justify-between gap-3 mb-2 md:hidden'>
              <div className='flex flex-1 overflow-hidden bg-white rounded-lg shadow-md'>
                <button
                  onClick={() => {
                    setShowDashboard(true);
                    setSubmittedState(null);
                  }}
                  className={`flex-1 flex items-center justify-center p-2 ${
                    submittedState === null
                      ? 'bg-primary-500 text-white'
                      : 'text-gray-600 hover:bg-gray-50'
                  }`}
                >
                  <span className='text-[10px] sm:text-xs font-medium md:text-sm'>Summary</span>
                </button>
                <button
                  onClick={() => {
                    setShowDashboard(false);
                    setSubmittedState(false);
                    setPageNumber(1);
                  }}
                  className={`flex-1 flex items-center justify-center p-2 ${
                    submittedState === false
                      ? 'bg-primary-500 text-white'
                      : 'text-gray-600 hover:bg-gray-50'
                  }`}
                >
                  <span className='text-[10px] sm:text-xs font-medium md:text-sm'>
                    Not Submitted
                  </span>
                </button>
                <button
                  onClick={() => {
                    setShowDashboard(false);
                    setSubmittedState(true);
                    setPageNumber(1);
                  }}
                  className={`flex-1 flex items-center justify-center p-2 ${
                    submittedState === true
                      ? 'bg-primary-500 text-white'
                      : 'text-gray-600 hover:bg-gray-50'
                  }`}
                >
                  <span className='text-[10px] sm:text-xs font-medium md:text-sm'>Submitted</span>
                </button>
              </div>

              {/* Mobile Filter Button */}
              <button
                type='button'
                onClick={() => setIsMobileFilterOpen(true)}
                className='flex items-center justify-center w-10 text-white rounded-lg h-7 bg-primary-600'
              >
                <HiFilter className='w-5 h-5' />
              </button>
            </div>

            {/* Mobile Date Filter Modal */}
            {isMobileFilterOpen && (
              <div className='fixed inset-0 z-50 flex items-center justify-center p-4 overflow-y-auto bg-black bg-opacity-30 md:hidden'>
                <div
                  ref={mobileFilterRef}
                  className='bg-white rounded-lg shadow-xl max-w-md w-full max-h-[90vh] overflow-y-auto'
                >
                  <div className='flex items-center justify-between p-4 border-b'>
                    <h3 className='text-lg font-medium text-gray-900'>Filters</h3>
                    <button
                      type='button'
                      className='text-gray-400 hover:text-gray-500'
                      onClick={() => setIsMobileFilterOpen(false)}
                    >
                      <HiX className='w-6 h-6' />
                    </button>
                  </div>

                  <div className='p-4 space-y-4'>
                    {/* Date Range Filter */}
                    <div>
                      <h4 className='mb-2 text-sm font-medium text-gray-700'>Date Range</h4>
                      <div className='space-y-2'>
                        {dateFilterOptions.map((option) => (
                          <div key={option.value} className='flex items-center'>
                            <input
                              id={`date-${option.value}`}
                              name='date-filter'
                              type='radio'
                              checked={dateFilter === option.value}
                              onChange={() => {
                                setDateFilter(option.value);
                                if (option.value !== 'custom') {
                                  setDateRange([]);
                                }
                              }}
                              className='w-4 h-4 border-gray-300 text-primary-600 focus:ring-primary-500'
                            />
                            <label
                              htmlFor={`date-${option.value}`}
                              className='block ml-3 text-xs font-medium text-gray-700 md:text-sm'
                            >
                              {option.label}
                            </label>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>

                  <div className='flex justify-end px-4 py-3 space-x-3 border-t'>
                    <button
                      type='button'
                      className='px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50'
                      onClick={() => setIsMobileFilterOpen(false)}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            )}

            {/* Desktop Tabs */}
            <div className='hidden md:block'>
              <CustomTabs
                submittedState={submittedState}
                setPageNumber={setPageNumber}
                setSubmittedState={setSubmittedState}
                setShowDashboard={setShowDashboard}
              />
            </div>
          </div>

          {/* Desktop DateFilter */}
          <div className='flex-col hidden gap-3 md:flex xl:flex-row xl:items-center'>
            <DateFilter
              selectedFilter={dateFilter}
              isShowAllFilter={true}
              onFilterChange={(filter, customRange) => {
                setDateFilter(filter);
                if (customRange) {
                  setDateRange([customRange.startDate, customRange.endDate]);
                }
                setPageNumber(1);
              }}
              setCurrentPage={setPageNumber}
            />
          </div>
        </div>

        {isSubmittedLoading || isPageLoading || isNotSubmittedLoading ? (
          <div className='flex items-center justify-center flex-1'>
            <BouncingDotsLoader />
          </div>
        ) : (
          <>
            {showDashboard ? (
              <PracticeDashboard
                selectedSubjectId={selectedSubjectId}
                getDateFilterParams={getDateFilterParams}
                dateFilter={dateFilter}
                dateRange={dateRange}
              />
            ) : isEmpty(practiceSheet?.docs) && isEmpty(practiceSheetSubmitted?.docs) ? (
              <div className='items-center p-5 font-semibold text-center text-white rounded-lg md:p-10 bg-primary-700 md:rounded-2xl'>
                {EMPTY_PRACTICE_PAPER}
              </div>
            ) : (
              <div className='flex flex-col justify-between flex-1'>
                <AllPracticeSheets
                  docs={submittedState === false ? sortedPracticeSheets : allPracticeSheets}
                  isStartPracticePaper={submittedState === false}
                  isLoading={submittedState === false ? isNotSubmittedLoading : isSubmittedLoading}
                  submitted={!!submittedState}
                  type={!studentId ? USER_TYPE?.STUDENT : USER_TYPE?.GUARDIAN}
                />
                {((submittedState === false && practiceSheet?.totalPages !== 0) ||
                  (submittedState === true && practiceSheetSubmitted?.totalPages !== 0)) && (
                  <div className='mt-4'>
                    <PaginationComp
                      currentPage={pageNumber}
                      totalPages={
                        (submittedState === false
                          ? practiceSheet?.totalPages
                          : practiceSheetSubmitted?.totalPages) || 1
                      }
                      onPageChange={handlePageChange}
                      showIcons
                      className={`text-sm align-middle ${isMobileScreen ? 'flex justify-center' : ''}`}
                    />
                  </div>
                )}
              </div>
            )}
          </>
        )}
      </div>
      <Footer />
      <ZohoSalesIQ
        id={chatBotDetails.userId?.toString() || ''}
        name={chatBotDetails.name}
        email={chatBotDetails.email}
        contactNumber={chatBotDetails.contactNumber}
        role={chatBotDetails.role}
        page={chatBotDetails.landingScreen}
        activeCamera={chatBotDetails.activeCamera}
        activeMicrophone={chatBotDetails.activeMicrophone}
        activeSpeaker={chatBotDetails.activeSpeaker}
      />
    </div>
  );
}
