import moment from 'moment';
import { HiInformationCircle, HiOutlineClock } from 'react-icons/hi';
import { Tooltip } from 'flowbite-react';
import { EVALUATED_STATUS, EVALUATION_SUBMISSION_STATUS } from 'configs';

interface PracticePaper {
  practiceSheetId: string;
  practiceSheetName: string;
  assignedOn: string;
  subjectName: string;
  studentSubmissionStatus: string;
  evaluationStatus: string;
  evaluationId: string;
  studentId: number;
  totalQuestions: string;
  totalQuestionsAttempted: string;
  totalQuestionsCorrect: string;
  totalQuestionsCorrectPercentage: string;
}

interface MobileCardProps {
  paper: PracticePaper;
  onStartPractice: (params: {
    practiceSheetId: string;
    evaluationId: string;
    studentId: number;
    evaluationStatus: string;
  }) => void;
}

const MobileCard = ({ paper, onStartPractice }: MobileCardProps) => {
  const isSubmitted = [
    EVALUATION_SUBMISSION_STATUS.CORRECT_SUBMISSION,
    EVALUATION_SUBMISSION_STATUS.SUBMITTED_WITHIN_DEADLINE,
    EVALUATION_SUBMISSION_STATUS.SUBMITTED_POST_DEADLINE,
  ].includes(paper.studentSubmissionStatus);

  const isEvaluated = paper.evaluationStatus === EVALUATED_STATUS.EVALUATED;
  const isEvaluationInProgress = isSubmitted && !isEvaluated;

  const getStatusBadgeStyle = (isPositive: boolean) =>
    isPositive
      ? 'bg-semantic-green-50 text-semantic-green-700'
      : 'bg-semantic-orange-50 text-semantic-orange-700';

  return (
    <div className='p-5 mb-4 bg-white shadow-sm rounded-xl'>
      <div className='space-y-4'>
        <div>
          <h3 className='mb-2 text-sm font-medium text-gray-900 lg:text-base'>
            {paper.practiceSheetName}
          </h3>
          <div className='flex items-center justify-between text-[10px] sm:text-xs md:text-sm'>
            <div className='flex items-center gap-1.5 bg-secondary-50 rounded-md px-2 py-1 text-gray-500'>
              <HiOutlineClock className='w-4 h-4' />
              <span>Assigned:</span>
              <span className='text-gray-600'>
                {moment(paper.assignedOn).format('MMM DD, YYYY')}
              </span>
            </div>
            <span className='px-2 py-1 text-xs text-gray-700 rounded-md md:text-sm bg-secondary-50'>
              {paper.subjectName}
            </span>
          </div>
        </div>

        <div className='flex items-center justify-between text-xs sm:text-sm'>
          <span className='text-gray-600 capitalize '>Submission Status</span>
          <span
            className={`px-3 py-1 font-medium rounded-full ${getStatusBadgeStyle(isSubmitted)}`}
          >
            {isSubmitted ? 'Submitted' : 'Not Submitted'}
          </span>
        </div>

        {paper.studentSubmissionStatus !== EVALUATION_SUBMISSION_STATUS.NOT_SUBMITTED && (
          <div className='flex items-center justify-between text-xs sm:text-sm'>
            <span className='text-gray-600 capitalize'>Evaluation Status</span>
            <span
              className={`px-3 py-1 font-medium rounded-full ${getStatusBadgeStyle(isEvaluated)}`}
            >
              {isEvaluated ? 'Evaluated' : 'Evaluation In-progress'}
            </span>
          </div>
        )}

        {isEvaluated && (
          <div className='flex items-center gap-2 text-xs sm:text-sm'>
            <span className='text-gray-600'>Result:</span>
            <div className='flex items-center gap-2'>
              <div className='flex items-center gap-1'>
                <span className='font-medium text-gray-700'>
                  {paper.totalQuestions}/{paper.totalQuestionsAttempted}/
                  {paper.totalQuestionsCorrect}
                </span>
              </div>
              <span className='px-2 py-0.5 font-medium text-semantic-green-700 bg-semantic-green-50 rounded-full'>
                {paper.totalQuestionsCorrectPercentage}
              </span>
              <Tooltip
                content={
                  <span className='text-xs whitespace-pre-wrap'>
                    Total Questions / Total Attempted (Correct + Incorrect) / Total Correct
                    (Percentage)
                  </span>
                }
                placement='top'
              >
                <HiInformationCircle className='w-4 h-4 text-gray-400 cursor-help' />
              </Tooltip>
            </div>
          </div>
        )}

        <div className='pt-1'>
          {isEvaluationInProgress ? (
            <div className='flex flex-col items-start gap-3 text-xs sm:text-sm'>
              <div className='w-full p-4 border shadow-sm bg-gradient-to-r from-amber-50 to-amber-100 rounded-xl border-amber-200'>
                <div className='flex items-center gap-3'>
                  <div className='p-2 bg-opacity-50 rounded-lg bg-amber-200'>
                    <HiInformationCircle className='w-5 h-5 text-amber-600' />
                  </div>
                  <div className='flex flex-col gap-1'>
                    <span className='text-sm font-medium md:text-base text-amber-800'>
                      Evaluation in progress
                    </span>
                    <span className='text-amber-600'>Answers will be visible post evaluation</span>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <button
              onClick={() =>
                onStartPractice({
                  practiceSheetId: paper.practiceSheetId,
                  evaluationId: paper.evaluationId,
                  studentId: paper.studentId,
                  evaluationStatus: paper.evaluationStatus,
                })
              }
              className={`
                w-full
                inline-flex items-center justify-center
                px-4 py-2.5
                text-xs sm:text-sm font-medium
                text-white
                border border-transparent
                rounded-lg
                capitalize
                transition-all duration-200
                ${
                  isEvaluated
                    ? 'bg-semantic-green-500 hover:bg-semantic-green-600 focus:ring-2 focus:ring-semantic-green-200'
                    : 'bg-primary-500 hover:bg-primary-600 focus:ring-2 focus:ring-primary-200'
                }
              `}
            >
              <span>{isEvaluated ? 'View Answers' : 'Start Practice'}</span>
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default MobileCard;
