import moment from 'moment';
import { useEffect, useRef, useState } from 'react';
import DateRangePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { DateFilterOption, DateFilterProps } from 'types';

const DateFilter = ({
  selectedFilter,
  onFilterChange,
  isCustomDateFilter = false,
  setCurrentPage,
  isShowAllFilter = false,
  isClassNotes = false,
}: DateFilterProps) => {
  const defaultStartDate = moment().subtract(4, 'days').toDate();
  const defaultEndDate = moment().toDate();

  const [dateRange, setDateRange] = useState<[Date | null, Date | null]>([
    defaultStartDate,
    defaultEndDate,
  ]);
  const [startDate, endDate] = dateRange;
  const [showDatePicker, setShowDatePicker] = useState<boolean>(false);
  const datePickerRef = useRef<HTMLDivElement>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);

  // Add validation before formatting dates
  const formattedStartDate = startDate ? moment(startDate).format('ll') : '';
  const formattedEndDate = endDate ? moment(endDate).format('ll') : '';

  // Handle clicking outside to close the date picker
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        datePickerRef.current &&
        !datePickerRef.current.contains(event.target as Node) &&
        buttonRef.current &&
        !buttonRef.current.contains(event.target as Node) &&
        !document.querySelector('.react-datepicker')?.contains(event.target as Node)
      ) {
        setShowDatePicker(false);
      }
    };

    if (showDatePicker) {
      document.addEventListener('mousedown', handleClickOutside);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showDatePicker]);

  useEffect(() => {
    if (selectedFilter === 'custom') {
      setShowDatePicker(true);
    }
  }, [selectedFilter]);

  const handleFilterClick = (filter: DateFilterOption) => {
    if (selectedFilter === filter) {
      if (filter === 'custom') {
        setShowDatePicker(!showDatePicker);
      }
      return;
    }

    if (filter === 'custom') {
      onFilterChange(filter, {
        startDate: startDate || defaultStartDate,
        endDate: endDate || defaultEndDate,
      });
      setDateRange([startDate || defaultStartDate, endDate || defaultEndDate]);
      setShowDatePicker(true);
    } else {
      onFilterChange(filter);
    }
  };

  return (
    <div className='flex flex-col justify-center'>
      <div className='flex flex-wrap items-center gap-3 font-semibold'>
        <div className='inline-flex flex-wrap p-1 rounded-lg bg-secondary-100'>
          <button
            onClick={() => handleFilterClick('last_30_days')}
            className={`px-4 py-1.5 text-xs md:text-sm transition-all duration-200 rounded-lg ${
              selectedFilter === 'last_30_days'
                ? 'bg-semantic-green-500 text-white shadow-sm font-medium'
                : 'text-secondary-700 hover:bg-secondary-200'
            }`}
          >
            Last 30 Days
          </button>

          {!isClassNotes && (
            <>
              <button
                onClick={() => handleFilterClick('current_month')}
                className={`px-4 py-1.5 text-xs md:text-sm transition-all duration-200 rounded-lg ${
                  selectedFilter === 'current_month'
                    ? 'bg-semantic-green-500 text-white shadow-sm font-medium'
                    : 'text-secondary-700 hover:bg-secondary-200'
                }`}
              >
                Current Month
              </button>
              <button
                onClick={() => handleFilterClick('past_month')}
                className={`px-4 py-1.5 text-xs md:text-sm transition-all duration-200 rounded-lg ${
                  selectedFilter === 'past_month'
                    ? 'bg-semantic-green-500 text-white shadow-sm font-medium'
                    : 'text-secondary-700 hover:bg-secondary-200'
                }`}
              >
                Past Month
              </button>
            </>
          )}
          {isShowAllFilter && (
            <button
              onClick={() => handleFilterClick('all')}
              className={`px-4 py-1.5 text-xs md:text-sm transition-all duration-200 rounded-lg ${
                selectedFilter === 'all'
                  ? 'bg-semantic-green-500 text-white shadow-sm font-medium'
                  : 'text-secondary-700 hover:bg-secondary-200'
              }`}
            >
              All
            </button>
          )}
          {isCustomDateFilter && (
            <div className='relative'>
              <button
                ref={buttonRef}
                onClick={() => handleFilterClick('custom')}
                className={`px-4 py-1.5 text-xs md:text-sm transition-all duration-200 rounded-lg whitespace-nowrap ${
                  selectedFilter === 'custom'
                    ? 'bg-semantic-green-500 text-white shadow-sm font-medium'
                    : 'text-secondary-700 hover:bg-secondary-200'
                }`}
              >
                {selectedFilter === 'custom' && startDate && endDate
                  ? `${formattedStartDate} - ${formattedEndDate}`
                  : 'Custom Range'}
              </button>

              {/* Using parent container for date picker */}
              <div
                ref={datePickerRef}
                className={`fixed inset-0 z-50 flex items-start justify-center pointer-events-none ${
                  showDatePicker ? 'block' : 'hidden'
                }`}
                style={{
                  top: buttonRef.current
                    ? buttonRef.current.getBoundingClientRect().bottom + window.scrollY + 8
                    : 0,
                }}
              >
                <div
                  className='p-4 bg-white border border-gray-200 rounded-lg shadow-xl pointer-events-auto'
                  style={{
                    position: 'absolute',
                    left: buttonRef.current
                      ? Math.min(
                          buttonRef.current.getBoundingClientRect().left + window.scrollX,
                          window.innerWidth - 340,
                        )
                      : 0,
                    top: 0,
                    zIndex: 9999,
                  }}
                >
                  <DateRangePicker
                    onChange={(update) => {
                      if (Array.isArray(update)) {
                        const [newStartDate, newEndDate] = update;
                        setDateRange([newStartDate, newEndDate]);
                        if (newStartDate && newEndDate) {
                          onFilterChange('custom', {
                            startDate: newStartDate,
                            endDate: newEndDate,
                          });
                          setCurrentPage(1);
                        }
                      }
                    }}
                    selectsRange={true}
                    startDate={startDate || undefined}
                    endDate={endDate || undefined}
                    maxDate={new Date()}
                    inline
                    monthsShown={1}
                    minDate={moment().subtract(1, 'year').toDate()}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default DateFilter;
